import React from 'react';
import HalfBanner from "../../components/halfBanner";
import Layout from '../../components/layout';
import Seo from "../../components/seo";
import OurService from "../../components/ourservice";
import Features from "../../components/features";
import Testimonials from "../../components/testimonials";
import RequestQuote from "../../components/quote";

const Services = () => {
    return (
        <Layout>
            <Seo title="Services" />
            <HalfBanner
                title="Services"
            />
            <OurService />
            <Features />
            {/* <Testimonials /> */}
            <RequestQuote />
        </Layout>

    );
};

export default Services;